import React from 'react';
import { Link } from 'react-router-dom';
import { redirection } from '../config/routings';

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-text-cover">
                <div className="copyright-text">
                  Copyright © {(new Date().getFullYear())} Visionary Technologies, LLC. All Rights Reserved
                    </div>
                <ul className="footer-list">
                  <li>
                    <Link to={redirection.faq}>FAQ's</Link>
                  </li>
                  <li>
                    <Link to={redirection.termsAndConditionPage}>Terms & Condition</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


