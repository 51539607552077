// import axios from 'axios'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/_index.sass';
// import dashboard from '../../assets/images/dashboard.png';
import dashboard from '../../assets/images/Group 9311.svg';
import ApiLoader from '../ApiLoader';

import { useHistory } from 'react-router-dom'
import { baseUrl } from '../../config/config';
// import score_profile from '../../assets/images/score-profile.png';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
import { redirection } from '../../config/routings';
// import DoughnutChart from '../DoughnutChart';

function TeacherDashboard() {
  const [dashData, setDashData] = useState([]);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  const submissionPage = () => {
    history.push(redirection.createLesson);
  }

  const searchReportsListenDBNavigate = () => {
    history.push(redirection.lessonInsightDBListing);
  }
  const reportsNavigate = () => {
    history.push(redirection.reportsListing);
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setDashData(res.data);
        setLoader(false);
        // console.log('dashboard',res.data);
      }).finally(() => setLoader(false))
  }, [])

  return (
    <>
      {loader && <ApiLoader />}
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>Teacher Dashboard</h1>
                <div style={{ float: 'right', display: 'inherit' }}>
                {/* <button type="button" onClick={searchReportsListenDBNavigate} className="Prev-average-btn">Lesson In∑ights DB</button> */}
                {/* <button onClick={reportsNavigate} className="Prev-average-btn record-btn">My In∑ights</button> */}
                  <button onClick={submissionPage} className="record-btn">
                    Create Lesson
                  </button>
                </div>
              </div>
              <div className="dashboard-body">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 paddding-zero">
                      <div className="score-box">
                        <div className="score-profile-img">
                          {/* <img src={score_profile} alt="" /> */}
                          {dashData?.data?.userdata?.profile_picture && dashData?.data?.userdata?.profile_picture_path !== null
                            ? <img src={dashData?.data?.userdata?.profile_picture_path + dashData?.data?.userdata?.profile_picture} alt="" />
                            : <i className="fa fa-user fa-5x" />
                          }
                        </div>
                        <div className="score-info">
                          <div className="score-text">
                            Hi, {dashData?.data?.userdata?.first_name} {dashData?.data?.userdata?.last_name}
                          </div>
                          <div className="score">
                            Current average level <span>{dashData?.data?.avgLevelNo}</span> - {dashData?.data?.avgLevel}
                          </div>
                          <div className="score-text">
                            based on prior submissions
                          </div>
                        </div>
                      </div>

                      <br />
                      <div className="evaluate-cover">
                        <div className="evaluate">
                          <img src={dashboard} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default TeacherDashboard
