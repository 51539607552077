/* eslint-disable */
import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/_index.sass';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import ProfileDropdown from './ProfileDropdown';
import { redirection } from '../../config/routings';

function TeacherHeader() {
  const [activeReport, setActiveReport] = useState(
    window.location.pathname === redirection.reportsListing ||
      window.location.pathname === redirection.createLesson ||
      window.location.pathname === redirection.recording
      ? true : false
  )
  const [activeDashboard, setActiveDashboard] = useState(
    window.location.pathname === redirection.dashboard
      ? true : false
  )
  const [activeLessonInsightDB, setActiveLessonInsightDB] = useState(
    window.location.pathname === redirection.lessonInsightDBListing ? true : false
  )
  const [previousAvgLevel, setPreviousAvgLevel] = useState(
    window.location.pathname === redirection.previousLevel ? true : false
  )

  function tooglerDashboard() {
    setActiveDashboard(true);
  }

  function tooglerReport() {
    setActiveReport(true);
  }

  function toggleInsightDBListing() {
    setActiveLessonInsightDB(true);
  }

  function togglePreviousAvgLevel() {
    setPreviousAvgLevel(true);
  }

  return (
    <>
      <div className="header-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <a className="navbar-brand">
                  <img src={logo} alt="test-img" />
                </a>
                <ProfileDropdown />
              </nav>
            </div>
          </div>
        </div>
      </div>
      {
        localStorage.getItem('token')
          ? <div className="navbar-cover">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg navbar-light navbar-bg">

                    {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button> */}

                    <div className="navbar-wrapper">
                      <ul className="navbar-nav mr-auto">
                        <li onClick={tooglerDashboard}
                          className={activeDashboard ? 'nav-item active' : 'nav-item'}>
                          <Link to={redirection.dashboard} className="nav-link">Dashboard</Link>
                        </li>
                        <li onClick={toggleInsightDBListing}
                          className={activeLessonInsightDB ? 'nav-item active' : 'nav-item'}>
                          <Link to={redirection.lessonInsightDBListing} className="nav-link">Lesson In∑ights DB</Link>
                        </li>
                        <li onClick={tooglerReport}
                          className={activeReport ? 'nav-item active' : 'nav-item'}>
                          <Link to={redirection.reportsListing} className="nav-link">My In∑ights</Link>
                        </li>
                        <li onClick={togglePreviousAvgLevel}
                          className={previousAvgLevel ? 'nav-item active' : 'nav-item'}>
                          <Link to={redirection.previousLevel} className="nav-link">Previous Average Level</Link>
                        </li>
                        
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          : ''
      }

    </>
  )
}

export default TeacherHeader
