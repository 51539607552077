// /* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/sass/_index.sass';
// import sort from '../../assets/images/sort.png';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { baseUrl } from '../../config/config';
import ApiLoader from '../ApiLoader';
import ReactPaginate from 'react-paginate';
import { CSVLink } from "react-csv";
// import play from '../../assets/images/play.png';
import Moment from 'moment';
// import {DateRangePicker} from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
import DoughnutChart from '../DoughnutChart';
import {Modal} from 'react-bootstrap';
import { redirection } from '../../config/routings';
import { toast, ToastContainer } from 'react-toastify';

function TeacherReport() {
  const csvRef = useRef(null);
  const history = useHistory();
  const [profileData, setProfileData] = useState([]);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState('');
  const [listModal, setListModal] = useState({});
  const [donughtData, setdonughtData] = useState('');
  const [audioRecordingModal, setAudioRecordingModal] = useState(false);
  const [recordID, setRecordID] = useState("");
  const [lessonName, setLessonName] = useState("");
  const token = localStorage.getItem('token');
  const [modalState, setModalState] = useState( "modal-one" | "modal-two" | "close" );

  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  const handleShowModalOne = (list) => {
    setLoader(true)
    setModalState("modal-one");
    const formData = new FormData();
    formData.append("record_id", list.id)

    axios.post(baseUrl + 'get-lession-details', formData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setLoader(false);
        console.log('data',res.data.message);
        setListModal(res.data.message);
      })
      .finally(() => setLoader(false))
    // setListModal(list);
  }

  const previousLevel = () => {
    history.push(redirection.previousLevel);
  }
  
  const handleClose = () => {
    setModalState("close")
  }


  const submissionPage = () => {
    history.push(redirection.createLesson);
  }

  // dashboard api call
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setLoader(false);
        setProfileData(res.data.data);
        // console.log('data',res.data.data);
      })
      .finally(() => setLoader(false))
    // setSerialNo(() => serialNo+1);  
  }, [])

  // list of recording and pagination data
  useEffect(() => {
    const token = localStorage.getItem('token');
    setLoader(true)
    axios.get(baseUrl + `report?page=${pageNumber}&from=${startDateFun()}&to=${endDateFun()}&sortColoum=${column}&sortOrder=${order}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        // console.log('data',res.data.data.data);
        setData(res.data.data);
      })
      .finally(() => setLoader(false))

  }, [pageNumber, state, column, order])

  const startDateFun = () => {
    let year = state[0]?.startDate?.getFullYear();
    let month = state[0]?.startDate?.getMonth() + 1;
    let day = state[0]?.startDate?.getDate();

    if (month?.length < 2) {
      month = '0' + month;
    }

    if (day?.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
  const endDateFun = () => {
    let year = state[0]?.endDate?.getFullYear();
    let month = state[0]?.endDate?.getMonth() + 1;
    let day = state[0].endDate?.getDate();

    if (month?.length < 2) {
      month = '0' + month;
    }

    if (day?.length < 2) {
      day = '0' + day;
    }

    // alert([year, month, day].join('-'))

    return [year, month, day].join('-');
  }

  const pageCount = Math.ceil(data?.total / data?.per_page);
  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  }

  const headers = [
    { label: 'Submission Name', key: 'title' },
    { label: 'AudioFile', key: 'audiofile' },
    { label: 'Uploaded ON', key: 'updated_at' },
    { label: 'Level', key: 'level' },
    { label: 'Level Name', key: 'level_name' }
  ];
  const csvReport = {
    filename: 'Report.csv',
    headers: headers,
    data: allData
  };
  
  const downloadCSV = () => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + `report?type=full&from=${startDateFun()}&to=${endDateFun()}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setAllData(res.data.data);

        setTimeout(() => {
          csvRef.current.link.click()
        }, 500);
      })
  }

  const donughtDynamicValue = (id) => {
    setModalState("modal-two")
    setLoader(true)
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }
    const formData = new FormData()
    formData.append('submission_id', id);
    // const submission_id=id

    axios.post(baseUrl + 'level-report', formData , config)
    .then(res => {
      setdonughtData(res?.data)
      setLoader(false)
    } )
    .catch(err => console.log('donught api error'))
  }

  const handleFlagForDelete = (e, data) => {
    const formData = new FormData()
    formData.append('record_id', data?.id);
    formData.append('flag_for_deletion', e.target.checked ? 1 : 0);
    formData.append('delete_record_item', 0);
    formData.append('flagged_teacher_id', "");
    const token = localStorage.getItem('token');
    
    setLoader(true)
    axios.post(baseUrl + 'delete-submission', formData , { headers: { "Authorization": `Bearer ${token}` } })
    .then(res => {
      axios.get(baseUrl + `report?page=${pageNumber}&from=${startDateFun()}&to=${endDateFun()}&sortColoum=${column}&sortOrder=${order}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
          setData(res.data.data);
        })
      })
    .catch(err => console.log('Error in deletion or Flag for deletion'))
    .finally(( ) => setLoader(false))
  }

  const handleDeleteRecord = (data) => {
    const formData = new FormData()
    formData.append('record_id', data?.id);
    formData.append('flag_for_deletion', data.delete_flag);
    formData.append('delete_record_item', 1);
    formData.append('flagged_teacher_id', "");
    const token = localStorage.getItem('token');
    
    setLoader(true)
    axios.post(baseUrl + 'delete-submission', formData , { headers: { "Authorization": `Bearer ${token}` } })
    .then(res => {
      toast.success(res?.data?.message)
      axios.get(baseUrl + `report?page=${pageNumber}&from=${startDateFun()}&to=${endDateFun()}&sortColoum=${column}&sortOrder=${order}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
          setData(res.data.data);
        })
      })
    .catch(err => console.log('Error in deletion or Flag for deletion'))
    .finally(( ) => setLoader(false))
  }

  const redirectToRecording = (data) => {
    setRecordID(data?.id);
    setLessonName(data?.title);
    if(data.audiofile) {
      setAudioRecordingModal(true)
    }else {
      history.push(redirection.recording, {
        id: data.id,
        lesson_name: data.title,
      });
    }
  };

  const redirectToRecordingScreen = () => {
    history.push(redirection.recording, {
      id: recordID,
      lesson_name: lessonName,
    });
  }

  const handleAudioClose = () => {
    setAudioRecordingModal(false)
    setRecordID("");
    setLessonName("");
  }
  
  return (
    <>
      {loader && <ApiLoader />}
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>My In∑ights</h1>
                <div style={{ float: 'right', display: 'inherit' }}>
                  {/* <button onClick={previousLevel} className="Prev-average-btn">Previous Average Level</button> */}
                  <button onClick={submissionPage} className="record-btn">
                    Create Lesson
                  </button>
                </div>                
              </div>
              <div className="dashboard-body">
                <div className="report-table ">
                  <div className="table-header">
                    Overall Report
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Teacher Name</th>
                          <th scope="col">Grade</th>
                          <th scope="col">School Name</th>
                          <th scope="col">District</th>
                          <th scope="col">Total Audio</th>
                          <th scope="col">Avg. Level</th>
                          <th scope="col">Level Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{profileData?.userdata?.first_name} {profileData?.userdata?.last_name}</td>
                          <td>{profileData?.userdata?.grade}</td>
                          <td>{profileData?.userdata?.school_name}</td>
                          <td>{profileData?.userdata?.district_name}</td>
                          <td>{profileData?.userdata?.totalsubmissions}</td>
                          <td>{profileData?.avgLevelNo}</td>
                          <td>{profileData?.avgLevel}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {/* <div className="doughnut"> */}
                {/* <DoughnutChart data={profileData?.chartdata} totalSubm={profileData?.userdata?.totalsubmissions}/> */}
                {/* {donughtData ? <DoughnutChart data={donughtData} /> : <p className="donughtError">No Keyword Found For Doughnut Chart</p> } */}
                {/* </div> */}
                
                <div className="report-table-two">
                  <div className="table-header-cover">
                    <div className="table-header">
                      Prior Lesson Submissions
                    </div>
                    <div className="filter report-date-box">

                      <DateRange
                        editableDateInputs={true}
                        onChange={item => {
                          setState([item.selection])
                        }}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        showSelectionPreview={true}
                        startDatePlaceholder={'Start Date'}
                        endDatePlaceholder={'End Date'}
                        maxDate={new Date()}
                        className={"report-table-two table-responsive"}
                      />

                      <div className="submit-btn">
                        <button className="btn" onClick={downloadCSV}>Export Report</button>
                        <CSVLink
                          style={{ display: 'none' }}
                          {...csvReport}
                          ref={csvRef}
                        >
                          Export to CSV
                        </CSVLink>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table testing">
                      <thead>
                        <tr>
                          <th className='align-middle' scope="col">S.No.</th>
                          <th className='align-middle sort-header' scope="col">
                            <div className='d-flex align-items-center w-100 '> 
                              <span className="pr-1 mr-auto">Submission Name</span>
                              <button className="sort-icon">
                                <i
                                  onClick={() => {
                                    setColumn('title')
                                    setOrder('DESC')
                                  }}
                                  className="fa fa-caret-up" aria-hidden="true">
                                </i>
                                <i
                                  onClick={() => {
                                    setColumn('title')
                                    setOrder('ASC')
                                  }}
                                  className="fa fa-caret-down" aria-hidden="true">
                                </i>
                              </button>
                            </div>
                          </th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">In∑ight Details</th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Record Audio</th>
                          <th className='align-middle sort-header' scope="col">
                          <div className='d-flex align-items-center w-100'> 
                              <span className="pr-1 mr-auto"> Uploaded On</span>
                              <button className="sort-icon">
                                <i
                                  onClick={() => {
                                    setColumn('created_at')
                                    setOrder('DESC')
                                  }}
                                  className="fa fa-caret-up" aria-hidden="true">
                                </i>
                                <i
                                  onClick={() => {
                                    setColumn('created_at')
                                    setOrder('ASC')
                                  }}
                                  className="fa fa-caret-down" aria-hidden="true">
                                </i>
                              </button>
                            </div>
                          </th>
                          <th className='align-middle sort-header' scope="col">Level</th>
                          <th className='align-middle sort-header' scope="col">Level Name</th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Play</th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Flag for deletion</th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Delete Record</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data?.data?.length !== 0
                            ?
                            data?.data?.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td className='align-middle'>{((data?.current_page - 1) * 10) + (index + 1)}</td>
                                  <td className='align-middle'>{list.title}</td>
                                  <td className='align-middle' style={{textAlign: 'center'}}><button className='text-body btn btn-link border-0 lh-sm p-0' onClick={() => handleShowModalOne(list)}><i className="fa fa-eye"></i></button></td>
                                  <td className='align-middle' style={{textAlign: 'center'}}>
                                    <button className='record-btn btn btn-link border-0 lh-sm p-0' onClick={() => redirectToRecording(list)}>
                                      <i> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.459" height="22.546" viewBox="0 0 14.459 22.546">
                                          <path d="M245.745,260.072c0,1.065-.047,2.132.01,3.194a3.764,3.764,0,0,1-4.047,4.008,3.726,3.726,0,0,1-3.568-2.77,4,4,0,0,1-.115-.954q-.014-3.454,0-6.909a3.888,3.888,0,0,1,7.591-1.012,4.3,4.3,0,0,1,.126,1.026C245.755,257.794,245.745,258.933,245.745,260.072Z" transform="translate(-234.653 -252.917)" fill="#000" />
                                          <path d="M199.073,362.768a17.477,17.477,0,0,1-2.223-.789,7,7,0,0,1-4.063-6.459c-.022-.9,0-1.808,0-2.713v-.383h1.808c0,.483-.014.95,0,1.416a19.068,19.068,0,0,0,.148,2.81,5.385,5.385,0,0,0,10.62-.55c.1-1.069.043-2.152.057-3.229,0-.144,0-.289,0-.451h1.742c.017.02.047.038.047.055a33.49,33.49,0,0,1-.067,4A7.071,7.071,0,0,1,201.3,362.6c-.119.024-.238.051-.376.08v3.082h2.915v1.8h-7.68v-1.776h2.909Z" transform="translate(-192.776 -345.022)" fill="#000" />
                                        </svg>
                                      </i>  
                                    </button> 
                                  </td>
                                  <td className='align-middle'>{Moment(list.updated_at).format('MM-DD-YYYY')}</td>
                                  <td className='align-middle'>{list.status === 0 ? "Pending" : list.status === 2 ? "Failed" : <p onClick={() => donughtDynamicValue(list.id)} className="levelReport">{list.level}</p>}</td>
                                  <td className='align-middle'>{list.status === 0 ? "Pending" : list.status === 2 ? "N/A" : list.level_name}</td>
                                  <td className='align-middle' style={{textAlign: 'center'}}><button title={list.audiofile ? "Play" : "Record audio to play"} className={"playButtonReport"} disabled={list.audiofile ? false : true} onClick={() => window.open(`${process.env.REACT_APP_AWS_BASE_URL}/${list.audiofile}`)}><i className={list.audiofile ? "fa fa-play playColorBlue" : "fa fa-play playColor commonDisabled"}></i></button></td>
                                  <td className='align-middle' style={{textAlign: 'center'}}><input type="checkbox" onChange={(e) => handleFlagForDelete(e, list)} checked={list.delete_flag}/></td>
                                  <td className='align-middle' style={{textAlign: 'center'}}><button title={list.delete_flag ? "Delete" : "Enable flag for deletion to delete the record" } style={{ color: list.delete_flag ? 'red' : '' }}  onClick={(e) => handleDeleteRecord(list)} disabled={!list.delete_flag} className='playButtonReport'><i className={list.delete_flag ? "fa fa-trash deleteRed" : "fa fa-trash playColor commonDisabled" }></i></button></td>
                                </tr> 
                              )
                            })
                            :
                            <tr>
                              <td colSpan='7'>
                                <div className="RecordNotFound"> No Record Found</div>
                              </td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <Modal show={modalState === "modal-two"} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Level Chart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <DoughnutChart data={donughtData} />
                      {/* <Chart data={donughtData} /> */}
                    </Modal.Body>
                  </Modal>


                  {/* Details Popup */}
                  <Modal show={modalState === "modal-one"} size={"xl"} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>In∑ight Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="profile-right-panel">
                      <div className="profile-info-table">

                        <div className="profile-info-row">
                          <div className="profile-label">
                            Lesson Name
                          </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                          {listModal.title}
                          </div>
                        </div>

                        <div className="profile-info-row">
                          <div className="profile-label">
                            Grade
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.grade}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Subject
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                          {listModal.subject}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Expected Student Outcome
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.expected_student_outcome}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Lesson Predicted Level
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.predicted_level}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Learning Objective
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.learning_objective}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Lesson Description
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.lesson_description}
                          </div>
                        </div>

                        <hr />

                        {
                          listModal?.generated_ai_info ? 
                            <div dangerouslySetInnerHTML={{ __html: listModal?.generated_ai_info }}></div>
                          : "The system is processing your lesson. This will take about one minute. Please try again."
                        }
                      </div>
                      
                    </div>
                    </Modal.Body>
                  </Modal>

                  {/* Audio record pop up record audio again */}
                  <Modal show={audioRecordingModal} onHide={handleAudioClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Record Audio</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="profile-right-panel">Starting a new recording will replace the existing one. Do you want to continue?</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-secondary px-4" onClick={handleAudioClose}>No</button>
                        <button className="btn btn-primary px-4" onClick={redirectToRecordingScreen}>Yes</button>
                    </Modal.Footer>
                  </Modal>

                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'next'}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={'paginationBttns pagetest'}
                    previousLinkClassName={data?.total <= 10 && 'previousBttn'}
                    nextLinkClassName={data?.total <= 10 && 'nextBttn'}
                    disabledClassName={'paginationDisabled'}
                    activeClassName={data?.total <= 10 && 'paginationActive'}
                    breakLabel={'...'}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Footer />
    </>
  )
}

export default TeacherReport
