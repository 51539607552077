import React from 'react';
import {Link} from 'react-router-dom';
import error from '../assets/images/404@2x.png';
import { redirection } from '../config/routings';

function PageNotFound() {
  return (
    <section className="main_section wrong_part not_found">
      <div className="container">
        <div className="row homie">
          <div className="col-md-12">
            <div className="left_part">
              {/* <h1 className="some">404 | Page Not Found !</h1> */}
              {/* <h1 className="some exclamation">!</h1> */}
              <div className="bottom_buttons error_button">
                <img src={error} alt="" />
                <Link to={redirection.dashboard}>GO TO HOME</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PageNotFound
