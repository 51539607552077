import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import {PrivateRoute} from './PrivateRoute';
import Home from './components/Home';
import Login from './components/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import ResetPassword from './components/Login/ResetPassword';
import TeacherDashboard from './components/Teacher/TeacherDashboard';
import TeacherReport from './components/Teacher/TeacherReport';
import TeacherLessonInsightDB from './components/Teacher/TeacherLessonInsightDB';
import TeacherSubmission from './components/Teacher/TeacherSubmission';
import TeacherRecording from './components/Teacher/TeacherRecording';
import TeacherViewProfile from './components/Teacher/TeacherViewProfile';
// import TeacherResource from './components/Teacher/TeacherResource';
// import TeacherAboutUs from './components/Teacher/TeacherAboutUs';
import TeacherTermsCondition from './components/Teacher/TeacherTermsCondition';
// import TeacherContactUs from './components/Teacher/TeacherContactUs';
import TeacherFaq from './components/Teacher/TeacherFaq';
import EditProfile from './components/Teacher/EditProfile';
import PageNotFound from './components/PageNotFound';
import TeacherSubMenuResources from './components/Teacher/TeacherSubMenuResources';
import PreviousLevel from './components/Teacher/PreviousLevel';
import { redirection } from './config/routings';

function App() {

  return (
    <Provider store={store}>
      <>
        <BrowserRouter >
          <Switch>

            <Route exact path={redirection.home} component={Home} />
            <Route path={redirection.login} component={Login} />
            <Route path={redirection.forgotPassword} component={ForgotPassword} />
            <Route path={`${redirection.resetPassword}/:id`} component={ResetPassword} />

            <PrivateRoute path={redirection.dashboard} component={TeacherDashboard}  />
            <PrivateRoute path={redirection.reportsListing} component={TeacherReport} />
            <PrivateRoute path={redirection.lessonInsightDBListing} component={TeacherLessonInsightDB} />
            <PrivateRoute path={redirection.createLesson} component={TeacherSubmission} />
            <PrivateRoute path={redirection.recording} component={TeacherRecording} />
            <PrivateRoute path={redirection.profile} component={TeacherViewProfile} />
            <PrivateRoute path={`${redirection.subMenuResourcePages}/:resource`} component={TeacherSubMenuResources} />
            <PrivateRoute path={redirection.faq} component={TeacherFaq} />
            <PrivateRoute path={redirection.editProfile} component={EditProfile} />
            <PrivateRoute path={redirection.previousLevel} component={PreviousLevel} />

            <Route path={redirection.termsAndCondition} component={TeacherTermsCondition} />
            <Route component={PageNotFound} />

          </Switch>
        </BrowserRouter>
      </>
    </Provider>
  );
}

export default App;
