import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseUrl } from '../../config/config';
import { useHistory } from 'react-router-dom';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
// import teacher_profile from '../../assets/images/profile.png';
import 'font-awesome/css/font-awesome.min.css';
import { redirection } from '../../config/routings';

function TeacherViewProfile() {
  const history = useHistory();
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setProfileData(res.data.data);
        // console.log(res.data.data);
      })
  }, [])

  const editPage = () => {
    history.push(redirection.editProfile);
  }

  return (
    <>
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>My Profile</h1>
              </div>
              <div className="dashboard-body bg-white">
                <div className="profile-panel">
                  <div className="profile-left-panel">
                    <div className="profile-box">
                      <div className="profile-img-cover">
                        <div className="profile-img">
                          {/* <img src={teacher_profile} alt="" /> */}
                          { profileData?.userdata?.profile_picture && profileData?.userdata?.profile_picture_path !== null 
                            ? <img src={profileData?.userdata?.profile_picture_path + profileData?.userdata?.profile_picture} alt="" />                       
                            : <i className="fa fa-user fa-5x"/> 
                          }
                        </div>
                        <div className="profile-details">
                          <h3>{profileData?.userdata?.first_name}</h3>
                          <p>{profileData?.userdata?.email}</p>
                        </div>
                      </div>
                      <div className="submissions">
                        <div className="sub-count">
                          <p>Total Submissions</p>
                          <h5>{profileData?.userdata?.totalsubmissions}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile-right-panel">
                    <div className="profile-info-table">
                      <div className="profile-info-row">
                        <div className="profile-label">
                          Teacher
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          {profileData?.userdata?.first_name} {profileData?.userdata?.last_name}
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-label">
                          Grade
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          {profileData?.userdata?.grade}
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-label">
                          School Name
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          {profileData?.userdata?.school_name}
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-label">
                          District Name
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          {profileData?.userdata?.district_name}
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-label">
                          Mobile Number
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          {profileData?.userdata?.phone_number}
                        </div>
                      </div>
                      <div className="profile-info-row">
                        <div className="profile-label">
                          Avg. Level
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          {profileData?.avgLevelNo}
                        </div>
                      </div>
                    </div>
                    <button className="btn btn-danger float-right" onClick={editPage}>
                      <i className="fa fa-edit" /> Edit Profile
										</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TeacherViewProfile
