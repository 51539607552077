const subPath = process.env.REACT_APP_SUB_PATH;

export const redirection = {
    home: `${subPath}/`,
    login: `${subPath}/login`,
    forgotPassword: `${subPath}/forgot-password`,
    resetPassword: `${subPath}/reset-password`,
    
    dashboard: `${subPath}/teacher-dashboard`,
    createLesson: `${subPath}/teacher-submission`,
    reportsListing: `${subPath}/teacher-report`,
    lessonInsightDBListing: `${subPath}/teacher-lesson-insight`,
    searchReportsListing: `${subPath}/teacher-report-search`,
    profile: `${subPath}/teacher-profile`,
    editProfile: `${subPath}/teacher-EditProfile`,
    recording:  `${subPath}/teacher-recording`,
    previousLevel: `${subPath}/teacher-previous-level`,
    subMenuResourcePages: `${subPath}/page`,
    


    faq: `${subPath}/teacher-faq`,
    termsAndCondition: `${subPath}/teacher-termsCondition`,
    termsAndConditionPage: `${subPath}/page/terms-and-conditions`,
}

