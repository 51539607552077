import React, { useState } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {baseUrl} from '../../config/config';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import '../../assets/sass/_login.sass';
import logo from '../../assets/images/logo.png';
import computer from '../../assets/images/computer-login.svg';
import { redirection } from '../../config/routings';

function ResetPassword() {
  let {id} = useParams();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [passLessThanSixError ,setPassLessThanSixEror] = useState(false);
  // const [success, setSuccess] = useState(false);
  const [diffPass, setDiffPass] = useState(false);
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
    submitted: false
  })

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setData((data) => ({ ...data, submitted: true }))
    // validation on submit
    let error = false;
    if (data.password === "" || data.confirmPassword === "") {
      error = true;
    } else {
      if (!new RegExp(/[?=.{6,}]/g).test(data.password) || !new RegExp(/[?=.{6,}]/g).test(data.confirmPassword)) {
        setPassLessThanSixEror(true);
        setDiffPass(false);
        error = true;
      }
    }
    if(data.password !== data.confirmPassword){
      setDiffPass(true);
      setPassLessThanSixEror(false);
      error = true
    }
    if (error) {
      return;
    }

    // base64 conversion to get 
    let str = id;
    let buff = new Buffer(str, 'base64');
    let base64ToStringNew = buff.toString('ascii');
    
    let binaryString = base64ToStringNew.split('~', 2);
    // console.log('token', binaryString[0]);
    // console.log('email', binaryString[1]);

    // console.log(id);
    const formData = new FormData()
    formData.append('password', data.password);
    formData.append('password_confirmation', data.confirmPassword);
    formData.append('token', id);
    formData.append('email', binaryString[1]);

    setLoader(true);
    axios.post(baseUrl+'reset-password', formData)
    .then(res => {
      console.log(res);
      if(!res?.data?.error) {
        // setSuccess(true);
        setDiffPass(false);
        setPassLessThanSixEror(false);
        toast.success('Password Updated Successfuly');
        setTimeout(() => {
          history.push(redirection.login);
        }, 3000);
        // setInvalidCredential(false);
      }
      // console.log(res.data)
      setData((data) => ({...data, password: ""}));
      setData((data) => ({...data, confirmPassword: ""}));
      setData((data) => ({ ...data, submitted: false }));
    })
    .catch(() => setLoader(false))
    
  }

  return (
    <>
    <div className="register-wrapper">
      <div className="register-outer">
        <div className="register-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="logo-panel">
                  <img src={logo} alt="test-img" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-2">
                <form onSubmit={handleSubmit}>
                  <div className="form-box">
                    <div className="register-title">
                      <h1>Reset Password</h1>
                    </div>
                    {/* {success && <div className="alert alert-success"> <strong>Password Updated Successfuly</strong></div> } */}
                    {diffPass && <div className="alert alert-danger"> <strong>Password & Confirm Password Must Be Same </strong></div> }
                    {passLessThanSixError && <div className="alert alert-danger"> <strong>Password Cannot be less than six </strong></div> }
                    <div className="register-form">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          value={data.password}
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          onChange={(e) => setData({ ...data, password: e.target.value })}
                        />
                        {data.submitted && data.password === "" && <p className="text-danger">Password is required.</p>}
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          value={data.confirmPassword}
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          placeholder="Confirm Password"
                          onChange={(e) => setData({ ...data, confirmPassword: e.target.value })}
                        />
                        {data.submitted && data.confirmPassword === "" && <p className="text-danger">Confirm Password is required.</p>}
                      </div>
                      <div className="btn-cover">
                        <button type="submit" className="submit-btn btn">
                          {loader ? <i className="fa fa-spinner fa-spin" /> : 'Update'}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="right-panel-img">
                  <img src={computer} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
    </>
  )
}

export default ResetPassword
