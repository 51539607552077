// /* eslint-disable */
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import '../../assets/sass/_index.sass';
// import sort from '../../assets/images/sort.png';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { baseUrl } from '../../config/config';
import ApiLoader from '../ApiLoader';
// import play from '../../assets/images/play.png';
import Moment from 'moment';
// import {DateRangePicker} from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
import { Modal } from 'react-bootstrap';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ToastContainer } from 'react-toastify';
import { redirection } from '../../config/routings';
import DoughnutChart from '../DoughnutChart';
import Footer from '../Footer';
import TeacherHeader from './TeacherHeader';

function TeacherReport() {
  const csvRef = useRef(null);
  const history = useHistory();
  const [profileData, setProfileData] = useState([]);
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState('');
  const [listModal, setListModal] = useState({});
  const [donughtData, setdonughtData] = useState('');
  const [modalState, setModalState] = useState( "modal-one" | "modal-two" | "close" );
  const [searchData, setSearchData] = useState("")
  const token = localStorage.getItem('token');

  const handleShowModalOne = (list) => {
    setLoader(true)
    setModalState("modal-one");
    const formData = new FormData();
    formData.append("record_id", list.id)

    axios.post(baseUrl + 'get-lession-details', formData, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setLoader(false);
        console.log('data',res.data.message);
        setListModal(res.data.message);
      })
      .finally(() => setLoader(false))
    // setListModal(list);
  }
  
  const handleClose = () => {
    setModalState("close")
  }

  // dashboard api call
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setLoader(false);
        setProfileData(res.data.data);
        // console.log('data',res.data.data);
      })
      .finally(() => setLoader(false))
    // setSerialNo(() => serialNo+1);  
  }, [])

  // list of recording and pagination data
  useEffect(() => {
    const token = localStorage.getItem('token');
    setLoader(true)
    axios.get(baseUrl + `lession-inside-db?search=${searchData}&page=${pageNumber}&sortColoum=${column}&sortOrder=${order}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setData(res.data.data);
      })
      .finally(() => setLoader(false))

  }, [pageNumber, searchData, column, order])

  const pageCount = Math.ceil(data?.total / data?.per_page);
  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
  }

  const donughtDynamicValue = (id) => {
    setModalState("modal-two")
    setLoader(true)
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }
    const formData = new FormData()
    formData.append('submission_id', id);
    // const submission_id=id

    axios.post(baseUrl + 'level-report', formData , config)
    .then(res => {
      setdonughtData(res?.data)
      setLoader(false)
    } )
    .catch(err => console.log('donught api error'))
  }

  const handleFlagForDelete = (e, data) => {
    const formData = new FormData()
    formData.append('record_id', data?.id);
    formData.append('flag_for_deletion', e.target.checked ? 1 : 0);
    formData.append('delete_record_item', 0);
    formData.append('flagged_teacher_id', "");
    const token = localStorage.getItem('token');
    
    setLoader(true)
    axios.post(baseUrl + 'delete-submission', formData , { headers: { "Authorization": `Bearer ${token}` } })
    .then(res => {
      axios.get(baseUrl + `lession-inside-db?search=${searchData}&page=${pageNumber}&sortColoum=${column}&sortOrder=${order}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then((res) => {
          setData(res.data.data);
        })
      })
    .catch(err => console.log('Error in deletion or Flag for deletion'))
    .finally(( ) => setLoader(false))
  }

  
  return (
    <>
      {loader && <ApiLoader />}
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>Lesson In∑ights DB</h1>
              </div>
              <div className="dashboard-body">
                <div className="report-table-two">
                  <div className="table-header-cover">
                    <div className="table-header">
                        {/* Lesson In∑ight DB */}
                    </div>
                    <div className="filter report-date-box">

                    <input
                        name="lesson_name"
                        type="text"
                        className="form-control"
                        placeholder="Name or Grade or Subject"
                        onChange={(e) => setSearchData(e.target.value)}
                      />

                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table testing">
                      <thead>
                        <tr>
                          <th className='align-middle' scope="col">S.No.</th>
                          <th className='align-middle sort-header' scope="col">
                            <div className='d-flex align-items-center w-100 '> 
                              <span className="pr-1 mr-auto">Submission Name</span>
                              <button className="sort-icon">
                                <i
                                  onClick={() => {
                                    setColumn('title')
                                    setOrder('DESC')
                                  }}
                                  className="fa fa-caret-up" aria-hidden="true">
                                </i>
                                <i
                                  onClick={() => {
                                    setColumn('title')
                                    setOrder('ASC')
                                  }}
                                  className="fa fa-caret-down" aria-hidden="true">
                                </i>
                              </button>
                            </div>
                          </th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Grade</th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Subject</th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">In∑ight Details</th>
                          <th className='align-middle sort-header' scope="col">
                          <div className='d-flex align-items-center w-100'> 
                              <span className="pr-1 mr-auto"> Uploaded On</span>
                              <button className="sort-icon">
                                <i
                                  onClick={() => {
                                    setColumn('created_at')
                                    setOrder('DESC')
                                  }}
                                  className="fa fa-caret-up" aria-hidden="true">
                                </i>
                                <i
                                  onClick={() => {
                                    setColumn('created_at')
                                    setOrder('ASC')
                                  }}
                                  className="fa fa-caret-down" aria-hidden="true">
                                </i>
                              </button>
                            </div>
                          </th>
                          <th className='align-middle' style={{textAlign: 'center'}} scope="col">Flag for deletion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data?.data?.length !== 0
                            ?
                            data?.data?.map((list, index) => {
                              return (
                                <tr key={index}>
                                  <td className='align-middle'>{((data?.current_page - 1) * 10) + (index + 1)}</td>
                                  <td className='align-middle'>{list.title}</td>

                                  <td className='align-middle'>{list.grade}</td>
                                  <td className='align-middle'>{list.subject}</td>
                                  {/* <td className='align-middle' style={{textAlign: 'center'}}><i onClick={() => handleShowModalOne(list)} className="fa fa-eye"></i></td> */}
                                  <td className='align-middle' style={{textAlign: 'center'}}><button className='text-body btn btn-link border-0 lh-sm p-0' onClick={() => handleShowModalOne(list)}><i className="fa fa-eye"></i></button></td>
                                  <td className='align-middle'>{Moment(list.updated_at).format('MM-DD-YYYY')}</td>
                                  <td className='align-middle' style={{textAlign: 'center'}}><input type="checkbox" onChange={(e) => handleFlagForDelete(e, list)} checked={list.delete_flag}/></td>
                                </tr> 
                              )
                            })
                            :
                            <tr>
                              <td colSpan='7'>
                                <div className="RecordNotFound"> No Record Found</div>
                              </td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>

                  <Modal show={modalState === "modal-two"} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Level Chart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <DoughnutChart data={donughtData} />
                      {/* <Chart data={donughtData} /> */}
                    </Modal.Body>
                  </Modal>


                  <Modal show={modalState === "modal-one"} size={"xl"} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>In∑ight Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="profile-right-panel">
                      <div className="profile-info-table">

                        <div className="profile-info-row">
                          <div className="profile-label">
                            Lesson Name
                          </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                          {listModal.title}
                          </div>
                        </div>

                        <div className="profile-info-row">
                          <div className="profile-label">
                            Grade
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.grade}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Subject
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                          {listModal.subject}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Expected Student Outcome
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.expected_student_outcome}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                            Lesson Predicted Level
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.predicted_level}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Learning Objective
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.learning_objective}
                          </div>
                        </div>
                        <div className="profile-info-row">
                          <div className="profile-label">
                          Lesson Description
                        </div>
                          <div className="separator">:</div>
                          <div className="profile-value">
                            {listModal.lesson_description}
                          </div>
                        </div>

                        <hr />
                        
                        {
                          listModal?.generated_ai_info ? 
                            <div dangerouslySetInnerHTML={{ __html: listModal?.generated_ai_info }}></div>                                              
                          : "The system is processing your lesson. This will take about one minute. Please try again."
                        }
                      </div>
                      
                    </div>
                    </Modal.Body>
                  </Modal>
                  

                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'next'}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={'paginationBttns pagetest'}
                    previousLinkClassName={data?.total <= 10 && 'previousBttn'}
                    nextLinkClassName={data?.total <= 10 && 'nextBttn'}
                    disabledClassName={'paginationDisabled'}
                    activeClassName={data?.total <= 10 && 'paginationActive'}
                    breakLabel={'...'}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Footer />
    </>
  )
}

export default TeacherReport
