import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import { baseUrl } from '../../config/config';
import {useHistory} from 'react-router-dom';
import TeacherHeader from './TeacherHeader';
import Footer from '../Footer';
// import teacher_profile from '../../assets/images/profile.png';
import 'font-awesome/css/font-awesome.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { redirection } from '../../config/routings';

function EditProfile() {
  const history = useHistory();
  const fileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [enableTextbox, setEnableTextbox] = useState(true);
  const [imageToggle, setImageToggle] = useState(true)
  const [submitted, setSubmitted] = useState(false);
  const [isPhoneNoValid, setIsPhoneNoValid] = useState(true);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    profile_picture: null,
    profile_picture_path: '',
    show_picture: null,
    email: ''
  });

  useEffect(() => {

    const token = localStorage.getItem('token');
    axios.get(baseUrl + 'dashboard', { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        setData((data) => ({ ...data, first_name: res.data.data.userdata.first_name }));
        setData((data) => ({ ...data, last_name: res.data.data.userdata.last_name }));
        setData((data) => ({ ...data, phone_number: res.data.data.userdata.phone_number }));
        setData((data) => ({ ...data, profile_picture: res.data.data.userdata.profile_picture }));
        setData((data) => ({ ...data, profile_picture_path: res.data.data.userdata.profile_picture_path }));
        setData((data) => ({ ...data, email: res.data.data.userdata.email }));
        // setData((data) => ({ ...data, profile_picture: res.data.data.userdata.profile_picture }));
        // console.log('dashboard', res.data);
      })
  }, [])

  const fileSelectedHandler = event => {
    setData((data) => ({ ...data, profile_picture: event.target.files[0] }));
    const reader = new FileReader();
    reader.onload = () => {
      if(reader.readyState === 2){
        setImageToggle(false)
        setData((data) => ({...data, show_picture: reader.result}))
      }
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  // console.log('all data', data?.profile_picture);
  // const fileUploadHandler = event => {
  //   // console.log()
  // }

  const updateProfile = (e) => {
    e.preventDefault();
    setSubmitted(() => true);
    let error = false;
    if (data.first_name === "" || data.last_name === "" || data.phone_number === "") {
      error = true;
    }
    if(data.phone_number !== "") {
      if (!new RegExp(/^[0-9\b]+$/).test(data.phone_number)) {
        setIsPhoneNoValid(() => false );
        error = true;
      }
    }
    if (error) {
      return;
    }
    setLoading(true);
    const token = localStorage.getItem('token');
    // console.log('all data', data?.profile_picture_path);
    const formData = new FormData();
    formData.append('profile_picture', data?.profile_picture);
    formData.append('first_name', data?.first_name);
    formData.append('last_name', data?.last_name);
    formData.append('phone_number', data?.phone_number);
    formData.append('password', data?.password);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
        'Authorization': `Bearer ${token}`
      }
    }
    axios.post(baseUrl+'update-profile',formData, config)
    .then((res) => {
      // console.log('response',res);
      toast.success('Profile Updated Successfully');
      // if(!enableTextbox && data.password!=='') { toast.success('Password Reset Successfully') };
      setTimeout(() => {
        history.push(redirection.profile) 
      }, 3000);
      
    })
  }

  const profilePageRedirect = () => {
    history.push(redirection.profile);
  }

  // const imgSrc =  data?.profile_picture_path + data?.profile_picture;
  // console.log('pic path and pic',data?.profile_picture_path + data?.profile_picture)
  return (
    
    <>
      <TeacherHeader />
      <div className="dashboard-cover">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="dashboard-title-cover">
                <h1>Edit Profile</h1>
              </div>
              <div className="dashboard-body bg-white">
                <div className="profile-panel edit-profile">
                  <div className="profile-left-panel">
                    <div className="profile-box">
                      <div className="profile-img-cover">
                        <div className="edit-icon">
                          <i onClick={() => fileInput.current.click()} className="fa fa-edit" />
                        </div>
                        <div className="profile-img">
                        {/* <img src={data?.show_picture} alt="" /> */}
                          {/* <img src={teacher_profile} alt="" /> */}
                          
                          {/* { data?.profile_picture !== null 
                            ? <img src={data?.profile_picture_path + data?.profile_picture} alt="" />
                            : <i class="fa fa-user fa-5x"/> 
                          } */}

                          {
                            imageToggle 
                              ? data?.profile_picture !== null
                                ? <img src={data?.profile_picture_path + data?.profile_picture} alt="" />
                                : <i className="fa fa-user fa-5x"/> 
                              :  <img src={data?.show_picture} alt="" />
                          }
                        </div>
                        <div className="choose-btn">
                        
                        <input
                          style={{display: 'none'}}
                          type="file"
                          accept="image/*" 
                          onChange={fileSelectedHandler}
                          ref={fileInput}
                        />
                        </div>
                        <div className="profile-details">
                          <h3>{data.first_name}</h3>
                          <p>{data.email}</p>
                        </div>
                      </div>
                      {/* <div className="submissions">
												<div className="sub-count">
													<p>Total Submissions</p>
													<h5>{profileData?.userdata?.totalsubmissions}</h5>
												</div>
											</div> */}
                    </div>
                  </div>
                  <div className="profile-right-panel">
                    <div className="profile-info-table">
                      <div className="profile-edit-row">
                        <div className="profile-label">
                          First Name
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          <input
                            name="first_name"
                            value={data.first_name}
                            type="text"
                            className="form-control"
                            onChange={(e) => setData({ ...data, first_name: e.target.value })}
                          />
                        </div>
                        {submitted && data.first_name === "" && <p className="text-danger">First Name is required.</p>}
                      </div>
                      <div className="profile-edit-row">
                        <div className="profile-label">
                          Last Name
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          <input
                            value={data.last_name}
                            name="last_name"
                            type="text"
                            className="form-control"
                            onChange={(e) => setData({ ...data, last_name: e.target.value })}
                          />
                        </div>
                        {submitted && data.last_name === "" && <p className="text-danger">Last Name is required.</p>}
                      </div>
                      <div className="profile-edit-row">
                        <div className="profile-label">
                          Mobile Number
											</div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          <input
                            value={data.phone_number}
                            name="phone_number"
                            type="text"
                            className="form-control"
                            onChange={(e) => setData({ ...data, phone_number: e.target.value })}
                          />
                        </div>
                        {submitted && data.phone_number === "" && <p className="text-danger">Mobile Number is required.</p>}
                        {submitted && !isPhoneNoValid && data.phone_number !== "" && <p className="text-danger">Mobile Number is Invalid.</p>}
                      </div>
                      <div className="profile-edit-row">
                        <div className="profile-label">
                          Password
											  </div>
                        <div className="separator">:</div>
                        <div className="profile-value">
                          <label className="password-label change-pass-link"
                            onClick={() => setEnableTextbox(!enableTextbox)}> 
                            <span>
                              <i className={enableTextbox ? "fa fa-lock" : "fa fa-unlock-alt" } aria-hidden="true"></i>
                            </span>
                            Click here! If you want to change your account password.
                          </label>
                          <input
                            disabled={enableTextbox}
                            name="password"
                            placeholder="Change Password"
                            type="password"
                            className="form-control"
                            onChange={(e) => setData({ ...data, password: e.target.value })}
                          />
                        </div>
                      </div>

                      <div className="btn-right-cover text-right mt-3">
                        
                        <button className="btn btn-danger mr-3"
                          onClick={profilePageRedirect}>
                          Cancel
                        </button>

                        <button className="btn btn-success" onClick={updateProfile}>
                          {loading ? <i className="fa fa-spinner fa-spin" /> : 'Update Profile'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
    
  )
}

export default EditProfile
